import axios from 'axios';
import { useState, useEffect } from 'react';
import { PERMISSIONS_TOOL_URL, DEFAULT_PERMISSIONS_ROLE } from '../utils/constants'


const useRole = (login) => {
    const [role, setRole] = useState<string>()
    // The current role implementation just divides users into "trainers" and "learners"
    // In order to have more granular permissions controls, we need to maintain a reference to
    // the exact role that is returned by the getRole call
    const [permissionsRole, setPermissionsRole] = useState<string>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(`${PERMISSIONS_TOOL_URL}/getrole/${login}`, {withCredentials: true})
        .then(response => {
            //Any role is treated as trainer for now
            const permissionsRole = response?.data?.Role;
            setPermissionsRole(permissionsRole)
            setRole("trainer")
            setLoading(false)
        })
        .catch(err => {
            //Anyone not present in the Permissions tool is considered a learner
            setPermissionsRole(DEFAULT_PERMISSIONS_ROLE)
            setRole("learner")
            setLoading(false)
        })
    })

    return { role, permissionsRole, loading }
}

export default useRole