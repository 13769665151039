import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserDetailsContext } from "../contexts/UserDetailsProvider";
import AuthUtils from "../utils/authUtils";


const useCohortTrainerAccessCheck = (cohortId: string | undefined) => {
    const { userDetails } = useContext(UserDetailsContext);
    const [cohortTrainerAccessAuthorized, setCohortTrainerAccessAuthorized] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        async function performAccessCheck() {
            if (cohortId) {
                if (await AuthUtils.hasCohortTrainerAccess(userDetails, cohortId)) {
                    setCohortTrainerAccessAuthorized(true);
                } else {
                    console.warn("Cohort trainer access denied. Redirecting to trainer homepage.");
                    navigate("/trainer");
                    window.location.reload();
                }
            }
        }
        performAccessCheck();
    }, [cohortId, userDetails]);
    return {
        cohortTrainerAccessAuthorized
    };
}

export default useCohortTrainerAccessCheck;