import { getCohortsForAlias } from "./api";
import UserDetails from "../types/UserDetails";

class AuthUtils {
        async hasCohortTrainerAccess(userContext: UserDetails, cohortId: string): Promise<boolean> {
            // If the user is an admin then grant cohort access
            const userContextString = JSON.stringify(userContext);
            if(this.isAdmin(userContext)){
                console.log(`Granting user: ${userContextString} trainer access to cohort: ${cohortId}`);
                return true;
            }
            const cohortDetails = await getCohortsForAlias(userContext.login);
            const targetCohort = cohortDetails.find((cohort)=>cohort.cohortId == cohortId);
            if(targetCohort) {
                const isCohortTrainer = targetCohort.trainerAlias == userContext.login;
                const isCohortDelegatedTrainer = targetCohort.delegateAliases.includes(userContext.login);
                // If the user is a trainer or delegated trainer for the cohort, then grant access
                if(isCohortTrainer || isCohortDelegatedTrainer){
                    console.log(`Granting user: ${userContextString} trainer access to cohort: ${cohortId}`);
                    return true;
                }
            }
            // If the user is not affiliated with the cohort or is not a trainer/delegated trainer 
            // for the cohort, then deny access.
            console.log(`Denying user: ${userContextString} trainer access to cohort: ${cohortId}`);
            return false;
        }

        isAdmin(userContext: UserDetails){
            // Return true if user is Admin or SuperAdmin
            return userContext.permissionsRole && userContext.permissionsRole.includes("Admin");
        }
    }

export default new AuthUtils();