import React, { useState } from 'react';
import CohortTable from './CohortTable';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row'
import Box from '@amzn/meridian/box'
import Text from '@amzn/meridian/text'
import useScoreCohort from '../../hooks/useScoreCohort';
import useCohortTrainerAccessCheck from "../../hooks/useCohortTrainerAccessCheck"
import { useParams } from 'react-router';
import Loader from '@amzn/meridian/loader';
import NewPracticeCase from '../NewPracticeCase/NewPracticeCase';

const CohortDashboard = () => {
    const { cohortId } = useParams()
    const { cohortTrainerAccessAuthorized } = useCohortTrainerAccessCheck(cohortId);
    const [cohortScores, cohortScoresLoading, cohortScoresError] = useScoreCohort(cohortId)

    const calculateAvgAR = () => {
        let totalCorrectActions = 0
        let totalActions = 0

        for (const cohort of cohortScores) {
            for (const alias of cohort.scores) {
                totalCorrectActions += alias.correctActions
                totalActions += alias.totalActions
            }
        }

        if (totalActions === 0) {
            return '-'
        }
        
        return ((totalCorrectActions / totalActions) * 100).toFixed(2)
    }

    const calculateGlobalAvgScore = () => {
        let totalSumOfCases = 0
        let totalSumOfScores = 0

        for (const cohort of cohortScores) {
            for (const learnerScore of cohort.scores) {
                totalSumOfCases = totalSumOfCases + learnerScore.cases.length;
                totalSumOfScores = totalSumOfScores + learnerScore.cases
                    .reduce((accumulator, current) => accumulator + current.score, 0);
            }
        }
        
        return (totalSumOfScores / totalSumOfCases).toFixed(2);
    }

    if (!cohortTrainerAccessAuthorized || cohortScoresLoading) return <Loader />
    return (
        <Column spacingInset={"500 200"}>
            <Row widths={"fill"}>
                {
                    cohortScores && !cohortScoresLoading && !cohortScoresError && cohortScores.length > 0 && cohortScores[0].scores &&
                    <>
                        <Box type={'outline'} height={200}>
                            <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                                <Text type={'h500'}>
                                    Class Size
                                </Text>
                                <Text type={'h500'}>
                                    {cohortScores[0].scores.length}
                                </Text>
                            </Column>
                        </Box>

                        <Box type={'outline'} height={200}>
                            <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                                <Text type={'h500'}>
                                    Average AR%
                                </Text>
                                <Text type={'h500'}>
                                    {calculateAvgAR()}%
                                </Text>
                            </Column>
                        </Box>

                        <Box type={'outline'} height={200}>
                            <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                                <Text type={'h500'}>
                                    Average Score
                                </Text>
                                <Text type={'h500'}>
                                    {cohortScores.some(cohort => 
                                        cohort.scores.some(scoredCohort => scoredCohort.cases.length > 0)) ? 
                                        calculateGlobalAvgScore():'-'}%
                                </Text>
                            </Column>
                        </Box>
                    </>
                }
                <Box height={200}>
                    <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                        <Text type={'h500'}>
                            Assign a Case
                        </Text>
                        <NewPracticeCase cohortId={cohortId} />
                    </Column>
                </Box>
            </Row >
            {
                cohortScores && cohortScores.length > 0 &&
                cohortScores.map((cohort) => {
                    const table = <CohortTable
                        key={cohort.templateName}
                        cohortScores={cohort.scores}
                        cohortScoresLoading={cohortScoresLoading}
                        cohortScoresError={cohortScoresError}
                        columnDisplayNames={cohort.columnDisplayNames}
                    />
                    if (cohortScores.length > 1) {
                        return (
                            <>
                                <Text type='h300'>{cohort.templateName}</Text>
                                {table}
                            </>
                        )
                    }
                    return table
                })
            }
        </Column >
    )
}

export default CohortDashboard;