import React, { createContext, useState, SetStateAction } from 'react'
import UserDetailsType from '../types/UserDetails'

type UserDetailsContextType = {
    userDetails: UserDetailsType
    setUserDetails: React.Dispatch<SetStateAction<UserDetailsType>> 
}

const initialState = {
    login: '',
    role: '',
    permissionsRole: ''
}

export const UserDetailsContext = createContext<UserDetailsContextType>({
    userDetails: initialState,
    setUserDetails: () => {}
})

const UserDetailsProvider = (props: any) => {
    const [userDetails, setUserDetails] = useState<UserDetailsType>(initialState)

    return (
        <UserDetailsContext.Provider value={ {userDetails, setUserDetails} }>
            { props.children }
        </UserDetailsContext.Provider>
    )
}

export default UserDetailsProvider